import { Component } from '@angular/core';
import { AuthenticationService } from '../../../../core/services/authentication.service';
import { AppAccessGuard } from '../../../../core/guards/app-access.guard';

@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrl: './customer.component.scss',
})
export class CustomerComponent {
  isUserAuthorized: boolean;

  constructor(
    private appAccessguard: AppAccessGuard) { }
  ngOnInit() {
    this.appAccessguard.authFlag$.subscribe(result => this.isUserAuthorized = result);
  }
}




