import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserPreferenceService } from './user-preference.service';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  defaultLang: any;
  languageKey: any;

  constructor(public translate: TranslateService,private userPreferenceService: UserPreferenceService) { }

  selectedLanguage() {
    this.userPreferenceService.getUserPreferencesObservable().subscribe(lang => {
      if (lang) {
        this.languageKey = lang.langKey;
        this.defaultLang = this.translate.getBrowserLang();
        this.defaultLang = this.translate.use(this.languageKey);
      }
    })
  }
}
