<div class="table-container mat-elevation-z8">
  <div class="title">
    <img [src]="imgIcon" />
    <h3>{{ programTableConfiguration?.tableTitle }}</h3>
  </div>
  <br><br>
  <mat-divider></mat-divider>
  <br>
  <form class="form-container">
    <div class="search-filter">
      <mat-form-field appearance="outline" class="form-container-wrapper">
        <mat-label>{{ searchLabel }}</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <img class="search-icon" [src]="TABLE_ASSETS.ICON" matSuffix />
        <input class="input-style" [formControl]="searchControl" type="text" matInput (keyup)="searchRecords($event)" />
      </mat-form-field>
    </div>

    <!-- Filters Row -->
    <div class="filter-row">
      <!-- Stage Filter -->
      <mat-form-field appearance="outline" class="rounded-field">
        <mat-label>Stage</mat-label>
        <mat-select [(ngModel)]="selectedStage" [ngModelOptions]="{standalone: true}" (selectionChange)="applyFilters()">
          <mat-option [value]="DEFAULT_VALUE">{{ DEFAULT_VALUE }}</mat-option>
          <mat-option *ngFor="let option of statusFilters" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Value Category Filter -->
      <mat-form-field appearance="outline" class="rounded-field">
        <mat-label>Value Category</mat-label>
        <mat-select [(ngModel)]="selectedValueCategory" [ngModelOptions]="{standalone: true}" (selectionChange)="applyFilters()">
          <mat-option [value]="DEFAULT_VALUE">{{ DEFAULT_VALUE }}</mat-option>
          <mat-option *ngFor="let option of valueCategoryFilters" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </form>

  <!-- Table Component -->
  <app-table
    [tableData]="programTableData"
    [SearchEvent$]="searchedRecord$"
    [tableConfiguration]="programTableConfiguration"
    (searchedText)="resetSearch()">
  </app-table>
</div>
<br>
