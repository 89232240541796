import { Component, Input, SimpleChanges, ViewChild, AfterViewInit, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TRANSFORMATION_TYPE, generic_tableConfiguration } from '../../constants/app.constants';
import { MatPaginator } from '@angular/material/paginator';
import { UserPreferenceService } from '../../../features/tvd-setup/services/user-preference.service';
import { ColumnDataType, ITableConfiguration } from '../../../features/tvd-setup/interfaces/ITable';
import { FilterService } from '../../../features/tvd-setup/services/filter.service';
import { Subject } from 'rxjs';
import { CustomerService } from '../../../features/tvd-setup/services/customer.service';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements AfterViewInit {

  @Input() tableConfiguration: ITableConfiguration = generic_tableConfiguration;//Generic Data for sample table
  @Input() tableData: any = [];
  @Input() isFilterApplied: boolean = false;
  @Input() SearchEvent$: Subject<string>;

  @Output() searchedText: EventEmitter<string> = new EventEmitter();
  @Output() rowInfo: EventEmitter<any> = new EventEmitter();

  filteredTableData: any = [];//extra variable to prevent manipulation of tableData
  transformationType = TRANSFORMATION_TYPE;
  dataSource: MatTableDataSource<any>
  columnDataType = ColumnDataType;
  currencyCode: string;
  customerName: string = '';

  @ViewChild(MatSort) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(
    private userPreferenceService: UserPreferenceService,
    private _customerService: CustomerService, private _filterService: FilterService, private detct: ChangeDetectorRef) { }

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.filteredTableData = this.tableData;
    this.userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
      if (preferences) {
        this.currencyCode = preferences.currency;
        this.currencyCode = preferences.currency;
      }
    })
    this.SearchEvent$?.subscribe(v => { this.searchRecords(v) });
  }

  makeChanges() {
    this.paginator?._changePageSize(this.tableConfiguration?.pageSize)
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.searchedText.emit(null);
  }

  resetSortState() {
    this.sort.active = '';
    this.sort.direction = '';
    this.sort.sortChange.emit();
  }

  onCustomerChange() {
    this._filterService.customerInfo.subscribe(custInfo => {
      if (!this.customerName || custInfo?.customerName.toLowerCase() !== this.customerName.toLowerCase()) {
        this.makeChanges();
        this.customerName = custInfo?.customerName;
        this.detct.detectChanges();
      }
    });
    this._customerService.isFilterApplied$.subscribe(
      isApplied => {
        if (isApplied) {
          this.makeChanges();
          this.resetSortState();
        }
      })
    this._customerService.isFilterApplied$.next(false);
    this.dataSource.sort = this.sort;
    if (this.sort) {
      this.resetSortState();
      this.sortDataBySumOfValues();
    }
  }


  ngOnChanges(changes: SimpleChanges) {
    if (changes['tableData'] && changes['tableData'].previousValue !== changes['tableData'].currentValue) {
      this.tableData = changes['tableData'].currentValue;
      this.filteredTableData = this.tableData;
      this.dataSource = new MatTableDataSource(this.filteredTableData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.onCustomerChange();
    }
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.onCustomerChange();
  }

  searchRecords(tbData: any) {
    this.filteredTableData = tbData;
    this.dataSource = new MatTableDataSource(this.filteredTableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.sortDataBySumOfValues()
  }
  /**
   * Sorts the data source by the sum of pipelineValue and totalValueDelivered in descending order.
   */
  sortDataBySumOfValues() {
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const sumA = (a.pipelineValue || 0) + (a.totalValueDelivered || 0);
      const sumB = (b.pipelineValue || 0) + (b.totalValueDelivered || 0);
      return sumB - sumA;  // Sort in descending order by sum
    });
  }

/**To open the Modal */
  viewMore(rowInfo:any)
  {
    this.rowInfo.emit(rowInfo);
  }
}
