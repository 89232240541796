import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, map, throwError } from 'rxjs';
import { ICVOCRequest, ICVOCResponse, ISavedCVOCHierarchy } from '../interfaces/ICVOCHierarchy';
import { environment } from '../../../../environments/environment';
import { IEVOCRequest, IEVOCResponse, SelectedEVOC } from '../interfaces/IEVOCHierarchy';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ISite, ISiteResponse } from '../interfaces/ISite.interface';
import { IOperatingTypePayload, IOperatingTypeResponse, ISavedOperatingType, ISubOperatingTypeResponse } from '../interfaces/IOperatingType.interface';
import { CustomerApi, ICustomerFilters, ICustomerInfo } from '../interfaces/customer.interface';
import { ICustomerDivision, ICustomerDivisionResponse } from '../interfaces/ICustomerDivision.interface';
import { FILTER_TYPES } from '../constant/tvd-constant';
import { ILDetailsRequest } from '../interfaces/IDetailsL1';
import { IProgramFilters } from '../interfaces/IProgram.interface';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public addedSites$: BehaviorSubject<ISite[]> = new BehaviorSubject(null);
  public selectedCVOC: BehaviorSubject<ISavedCVOCHierarchy[]> = new BehaviorSubject(null);
  public selectedEVOC: BehaviorSubject<SelectedEVOC[]> = new BehaviorSubject(null);
  public selectedCustomerDivision: BehaviorSubject<ICustomerDivision> = new BehaviorSubject(null);
  public selectedOperatingType$: BehaviorSubject<ISavedOperatingType> = new BehaviorSubject(null);
  //below are applied filters
  public appliedSites$: BehaviorSubject<ISite[]> = new BehaviorSubject(null);
  public appliedCVOC: BehaviorSubject<ISavedCVOCHierarchy[]> = new BehaviorSubject(null);
  public appliedEVOC: BehaviorSubject<SelectedEVOC[]> = new BehaviorSubject(null);
  public appliedCustomerDivision: BehaviorSubject<ICustomerDivision> = new BehaviorSubject(null);
  public appliedOperatingType$: BehaviorSubject<ISavedOperatingType> = new BehaviorSubject(null);
  public customerInfo: BehaviorSubject<ICustomerInfo> = new BehaviorSubject(null);
  public currentToggle: BehaviorSubject<string> = new BehaviorSubject(FILTER_TYPES.CVOC);

  public startDate: BehaviorSubject<string> = new BehaviorSubject(null);
  public endDate: BehaviorSubject<string> = new BehaviorSubject(null);
  filterType: any = FILTER_TYPES;

  public filterContainerName: BehaviorSubject<string> = new BehaviorSubject(null);

  public totalRecords: any;

  constructor(private http: HttpClient) { }


  private getDetails<T>(endPoint: string, customerID: string): Observable<T> {
    return this.http.get<T>(`${environment.API_URL_MULESOFT}/${customerID}/${endPoint}`).pipe(
      catchError(this.errorHandler));
  }

  fetchOperatingTypeList(customerID: string) {
    return this.getDetails<IOperatingTypeResponse>('operating-type', customerID).pipe(
      catchError(this.errorHandler));
  }

  fetchSubOperatingTypeList(customerID: String, opList: IOperatingTypePayload) {
    return this.http.post<ISubOperatingTypeResponse>(`${environment.API_URL_MULESOFT}/${customerID}/operating-type/sub-type`, opList).pipe(
      catchError(this.errorHandler));
  }

  getNextCVOCFilter(cvocRequest: ICVOCRequest, customerID: string): Observable<ICVOCResponse> {
    cvocRequest.startDate = this.startDate.value;
    cvocRequest.endDate = this.endDate.value;
    return this.http.post<ICVOCResponse>(`${environment.API_URL_MULESOFT}/${customerID}/customer-hierarchy-levels`, cvocRequest).pipe(
      catchError(this.errorHandler));
  }

  getNextEVOCFilter(IEvocRequest: IEVOCRequest, customerID: string): Observable<IEVOCResponse> {
    return this.http.post<IEVOCResponse>(`${environment.API_URL_MULESOFT}/${customerID}/ecolab-hierarchy-levels`, IEvocRequest).pipe(
      catchError(this.errorHandler));
  }

  getSites(customerID: string): Observable<ISiteResponse> {
    return this.getDetails<ISiteResponse>('sites', customerID);
  }
  fetchCustomerDivisions(customerID: string): Observable<ICustomerDivisionResponse> {
    return this.getDetails<ICustomerDivisionResponse>('divisions', customerID);
  }

  errorHandler(error: HttpErrorResponse) {
    console.error(error);
    return throwError(error.message || "Server Error");
  }

  getAppliedOptionalFilters(uom: string, currencyCode: string): ILDetailsRequest {
    const currrentToggle = this.currentToggle.value;
    const startDate = this.startDate.value
    const endDate = this.endDate.value
    const cvoc = this.selectedCVOC.value;
    const evoc = this.selectedEVOC.value;
    const division = this.selectedCustomerDivision.value;
    const site = this.addedSites$.value;
    const opType = this.selectedOperatingType$.value;

    const requestBody: ILDetailsRequest = {
      startDate: startDate,
      endDate: endDate,
      unitOfMeasure: uom,
      currencyCode: currencyCode
    };
    if (cvoc && currrentToggle === 'CVOC') {
      cvoc.forEach(x => {
        const a = 'hierarchyLevel' + x.level + 'Id';
        requestBody[a] = x.hierarchyID;
      });
    }
    if (evoc && currrentToggle === 'EVOC') {
      evoc.forEach(y => {
        requestBody[y.hierarchyName] = y.selectedValue;
      });
    }
    if (division) {
      const div = division.divisions.map(x => x);
      if (div.length > 0) {
        requestBody['division'] = div;
      }
    }
    if (site && currrentToggle === this.filterType.SITES) {
      const arr = site.map(x => x.siteId);
      if (arr.length > 0) {
        requestBody['siteId'] = arr;
      }
    }
    if (opType) {
      if (opType.subOpType.includes(opType.operatingType)) {
        requestBody['operatingType'] = opType.operatingType;
      } else {
        requestBody['operatingType'] = opType.operatingType;
        requestBody['operatingSubType'] = opType.subOpType;
      }
    }

    this.appliedSites$.next(site);
    this.appliedCVOC.next(cvoc);
    this.appliedEVOC.next(evoc);
    this.appliedOperatingType$.next(opType);
    this.appliedCustomerDivision.next(division);
    return requestBody;
  }

  resetAllSavedFilterData() {
    this.selectedCVOC.next(null);
    this.selectedEVOC.next(null);
    this.addedSites$.next(null);
    this.selectedCustomerDivision.next(null);
    this.selectedOperatingType$.next(null);
  }

  /**
   * Method used to get the program filters
   * @param customerID Customer ID
   * @param programFiltersReqPayload Request Filters to get the program filters
   * @returns Program filters
   */
  getProgramFilters(customerID: string, programFiltersReqPayload: IProgramFilters): Observable<CustomerApi> {
    return this.http
      .post<CustomerApi>(`${environment.API_URL_MULESOFT}/${customerID}/programs/filters`, programFiltersReqPayload)
      .pipe(
        map((data: any) => {
          return data || [];
        }),
        catchError((error) => {
          const errorMessage = `getProgramFilters: Error fetching status filters for Customer ID: ${customerID}. ${error.message}`;
          return throwError(() => new Error(errorMessage));
        })
      );
  }
}


