import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ITableConfiguration } from '../../interfaces/ITable';
import { UI_CONTROL_DEFAULT_VALUE_ALL, PROGRAM_TABLE, TABLE_SEARCH, UOM, USD, PROGRAM_MAPPER, generic_tableConfiguration } from '../../../../shared/constants/app.constants';
import { FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import { IProgramDetails, IProgramFilters } from '../../interfaces/IProgram.interface';
import { FilterService } from '../../services/filter.service';
import { UserPreferenceService } from '../../services/user-preference.service';
import { ILDetailsRequest } from '../../interfaces/IDetailsL1';
@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})

export class ProgramsComponent implements OnInit {
  programTableConfiguration: ITableConfiguration;
  programFilters: any = [];
  programTableData: any = [];
  filteredData: IProgramDetails[];
  statusFilters: string[] = [];
  valueCategoryFilters: string[] = [];
  searchedRecord$: Subject<string> = new Subject();
  searchControl = new FormControl('');

  TABLE_ASSETS = TABLE_SEARCH;
  imgIcon: string = PROGRAM_TABLE.ICON;
  selectedStage: string = UI_CONTROL_DEFAULT_VALUE_ALL;
  selectedValueCategory: string = UI_CONTROL_DEFAULT_VALUE_ALL;
  DEFAULT_VALUE = UI_CONTROL_DEFAULT_VALUE_ALL;

  searchLabel: string = '';
  textToSearch: string = '';
  private requestFilters: IProgramFilters;
  customerID: string;
  unitOfMeasure: string = UOM;
  currencyCode: string = USD;
  constructor(
    private customerService: CustomerService,
    public _filterService: FilterService,
    public _userPreferenceService: UserPreferenceService,) { }

  ngOnInit() {
    this.getUserPreferences();
    this.fetchProgramsInfo();
    this.fetchProgramFilters();
    this.generate_Programs_Configuration();
    this.customerService.isFilterApplied$.subscribe(
      val => {
        if (val) {
          this.fetchProgramsInfo();
          this.fetchProgramFilters();
        }
      }
    )
    this.searchLabel = `${this.TABLE_ASSETS.LABEL} Program Name`;
  }

  /**
   * Updates the status and value category filters based on the input programFilters.
   */
  private updateFilters(): void {
    const responseArray = this.programFilters?.response?.response || [];
    // Reset filters
    this.statusFilters = [];
    this.valueCategoryFilters = [];
    // Populate status and valueCategory filters
    responseArray.forEach((filterObj) => {
      if (filterObj.filterType === 'status') {
        this.statusFilters = filterObj.filters;
      } else if (filterObj.filterType === 'valueCategories') {
        this.valueCategoryFilters = filterObj.filters.filter((filter) => filter !== null);
      }
    });
  }

  /**
   * Applies filters to the program data based on the selected stage and value category.
   * Fetches the filtered data from the customer service.
   */
  applyFilters(): void {
    if (!Array.isArray(this.programTableData)) {
      this.filteredData = [];
      this.programTableData = [];
      return;
    }
    this.fetchProgramsInfo();
  }

  /**
   * Handles the 'All' selection logic for the filters (currentStatus and valueCategories).
   */
  private handleFilterSelections(): void {
    // Handle 'All' selection for currentStatus (selectedStage)
    if (this.selectedStage !== UI_CONTROL_DEFAULT_VALUE_ALL) {
      this.requestFilters.currentStatus = this.selectedStage;
    } else {
      delete this.requestFilters.currentStatus;
    }

    // Handle 'All' selection for valueCategories (selectedValueCategory)
    if (this.selectedValueCategory !== UI_CONTROL_DEFAULT_VALUE_ALL) {
      this.requestFilters.valueCategories = this.selectedValueCategory;
    } else {
      delete this.requestFilters.valueCategories;
    }
  }

  searchRecords(event: Event) {
    this.textToSearch = (event.target as HTMLInputElement).value;
    if (this.textToSearch != '') {
      this.programTableData = this.programTableData
        .filter(data => data.programName?.toLowerCase().includes(this.textToSearch.trim().toLowerCase()));
    } else {
      this.fetchProgramsInfo();
    }
    this.searchedRecord$.next(this.programTableData);
  }

  resetSearch() {
    this.searchControl.setValue(null);//upon customer change making input as null
  }

  /**
   * Method used to get the customer programs from the data source.
   */
  fetchProgramsInfo() {
    this._filterService.customerInfo.subscribe(data => {
      if (data) {
        this.customerID = data?.customerID;
        if (this.customerID) {
          const basereqPayload: ILDetailsRequest = this._filterService.getAppliedOptionalFilters(this.unitOfMeasure, this.currencyCode);
          this.requestFilters = basereqPayload;
          this.handleFilterSelections();
          this.customerService.getCustomerPrograms(this.customerID, this.requestFilters).subscribe((data) => {
            this.filteredData = data?.response?.programInformation || [];
            this.programTableData = this.filteredData;
            this.programTableData = this.programTableData
              .filter(data => data.programName?.toLowerCase().includes(this.textToSearch.trim().toLowerCase()));
          });
        }
      }
    });
  }

  /**
   * Method used to get the program filters
   * @param customerId Customer ID
   * @param programFiltersReqObj request filters associated to get the program filters
   */
  fetchProgramFilters() {
    this._filterService.customerInfo.subscribe(data => {
      if (data) {
        this.customerID = data?.customerID;
        if (this.customerID) {
          this.resetFilters();
          const basereqPayload: ILDetailsRequest = this._filterService.getAppliedOptionalFilters(this.unitOfMeasure, this.currencyCode);
          this.requestFilters = basereqPayload;
          this.handleFilterSelections();
          this._filterService.getProgramFilters(this.customerID, basereqPayload).subscribe(res => {
            this.programFilters = res;
            this.updateFilters();
          })
        }
      }
    });
  }

  /**
   * Method used to get the user preferences from the data source.
   */
  getUserPreferences(): any {
    this._userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
      if (preferences) {
        this.currencyCode = preferences.currency;
        this.unitOfMeasure = preferences.uom;
      }
    })
  }

  /**
   * Method used to generate the Programs configuration required for the component
   */
  generate_Programs_Configuration() {
    let columnList = PROGRAM_MAPPER.map(item => item.fieldName);
    this.programTableConfiguration = {
      columnList: columnList,
      mapper: PROGRAM_MAPPER,
      tableTitle: PROGRAM_TABLE.TITLE,
      tableType: PROGRAM_TABLE.TABLE_TYPE_CUSTOMER_PROGRAMS,
      pageSizeOptions: generic_tableConfiguration.pageSizeOptions,
      pageSize: generic_tableConfiguration.pageSize
    }
  }

  resetFilters() {
    this.selectedStage = UI_CONTROL_DEFAULT_VALUE_ALL;
    this.selectedValueCategory = UI_CONTROL_DEFAULT_VALUE_ALL;
  }
}
