import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICVOCRequest, ICustomerHierarchy, IHierarchy, ISavedCVOCHierarchy } from '../../interfaces/ICVOCHierarchy';
import { Observable, map, startWith } from 'rxjs';
import { savedHierarchy } from '../../constant/tvd-constant';
import { ICustomerInfo } from '../../interfaces/customer.interface';
import { FilterService } from '../../services/filter.service';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';

@Component({
  selector: 'app-cvoc-filter',
  templateUrl: './cvoc-filter.component.html',
  styleUrl: './cvoc-filter.component.scss'
})
export class CvocFilterComponent {

  @Output() enableApply:EventEmitter<any>=new EventEmitter();

  customerInfo:ICustomerInfo;
  cvoc_l1_control = new FormControl('');
  cvoc_l2_control = new FormControl('');
  cvoc_l3_control = new FormControl('');
  cvoc_l4_control = new FormControl('');
  cvoc_l5_control = new FormControl('');

  displayAllFilter: boolean = false;
  selectedFilterCount: number = 0;

  cvocInputControls: FormControl[] = [this.cvoc_l1_control, this.cvoc_l2_control, this.cvoc_l3_control, this.cvoc_l4_control, this.cvoc_l5_control];
  matcher = new CustomerNameErrorStateMatcher();
  filteredOptions_cvoc: Observable<IHierarchy[]>;
  cvocLevelResponse: ICustomerHierarchy;
  selectedCvocHierarchy: ISavedCVOCHierarchy[] = [...savedHierarchy];
  disableApply: boolean = true;
  cvocfilterIterations: number[] = new Array(1);
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  ngOnInit() {
    this._filterService.currentToggle.next('CVOC');
    this._filterService.selectedEVOC.next(null);//saved evoc is now null
    this._filterService.addedSites$.next(null);//saved sites are null
    this._filterService.customerInfo.subscribe((res)=>this.customerInfo=res);
    const saved_data=this._filterService.appliedCVOC.value;
    let isData:boolean;
    saved_data?.length > 0
    ? (this.fetchSavedCVOCValues(saved_data),isData=true) 
    : (this.fetchNextCVOCFilter(),isData=false)
      
    this.enableApply.emit({ apply: false, type: 'cvoc', data: isData })
  }

  //This method helps in fetching the CVOC values saved in Service variable
  fetchSavedCVOCValues(cvoc_data:ISavedCVOCHierarchy[])
  {
    if (!cvoc_data) return;

    this.selectedCvocHierarchy = [...cvoc_data];
    if(cvoc_data?.length==0)
    {
      this.modifyCVOCFilterCount(cvoc_data.length+1);
      return;
    }
    this.modifyCVOCFilterCount(cvoc_data.length);

    cvoc_data.forEach((data, i) => {
        this.cvocInputControls[i].setValue(data);
    });
   
  }

  constructor(private _filterService: FilterService, private cdref: ChangeDetectorRef) { }

  fetchNextCVOCFilter(targetLevel: number = 0, selectedFilter?: ISavedCVOCHierarchy[]) {

    const savedCVOCData = {
      customerName: this.customerInfo.customerName,
      hierarchyLevel1Id: selectedFilter?.[0]?.hierarchyID,
      hierarchyLevel2Id: selectedFilter?.[1]?.hierarchyID,
      hierarchyLevel3Id: selectedFilter?.[2]?.hierarchyID,
      hierarchyLevel4Id: selectedFilter?.[3]?.hierarchyID,
    };

    const cvocRequestObj: ICVOCRequest = Object.assign({}, ...Object.keys(savedCVOCData)
      .slice(0, targetLevel + 1)
      .map(key => ({ [key]: savedCVOCData[key] }))
    );
    this._filterService.getNextCVOCFilter(cvocRequestObj, this.customerInfo.customerID).subscribe(
      res => {
        this.cvocLevelResponse = res.response;
        this.filteredOptions_cvoc = this.cvocInputControls[targetLevel].valueChanges.pipe(
          startWith(''),
          map(value => this._cvocFilter(value || '')),
        );
      }
    )
    // this.enableApply.emit({apply:this.disableApply,type:'cvoc'});

  }




  //Below method filters the options based on change in Input control
  _cvocFilter(value: any): IHierarchy[] | null {
    if(typeof value!=='string')//to avoid the filteration logic as the option is directly selected
      return null;
    const filterValue = value?.toLowerCase();
    var res = this.cvocLevelResponse.hierarchy.filter(option => option.hierarchyName?.toLowerCase().includes(filterValue));
    return res;
  }

  openAllFilterView() {
    this.displayAllFilter = !this.displayAllFilter;
  }

  //Below method helps in validating the Input based on Dropdown options!
  validateCvocFilter(level: number) {
    const inputControl = this.cvocInputControls[level - 1];
    const validFilter = this.cvocLevelResponse?.hierarchy?.find(y => y?.hierarchyName?.toLowerCase().includes(inputControl.value?.toLowerCase()));
    let isData:boolean;
    if (!inputControl.value && level === 1) {
      this.disableApply = false;
      this.modifyCVOCFilterCount(level);
      this.selectedCvocHierarchy=[];
      this.persistCVOCValues();
      isData=false;   
    }
    else if(!inputControl.value && level!=1)
    {
      this.disableApply = false;
      this.modifyCVOCFilterCount(level);
      inputControl.setErrors(null);
      this.getCurrentLevelCVOCOptions(level-1,true);
      this.persistCVOCValues();
      isData=true;
    }
    else if (this.cvocLevelResponse?.hierarchy?.find(y => y?.hierarchyName?.toLowerCase()==inputControl.value?.toLowerCase())) {
      this.disableApply = false;
      inputControl.setErrors(null);
      isData=true;

    }
    else if(validFilter){this.disableApply=true;}
    else {
      this.disableApply = true;
      inputControl.setErrors({ 'invalid': true });
      this.modifyCVOCFilterCount(level);
      isData=true;
    }
    
     this.enableApply.emit({apply:this.disableApply,type:'cvoc',data:isData});
  }

  //Below method gives the dropdown values for the current level
  getCurrentLevelCVOCOptions(level: number, selection?: boolean) {
    if (!selection) {
      this.fetchNextCVOCFilter(level - 1, this.selectedCvocHierarchy);
    }
    const inputControlValue = this.cvocInputControls[level - 1].value?.hierarchyId;
    const currentSelectedFilter = this.cvocLevelResponse?.hierarchy?.find(y => y.hierarchyId === inputControlValue);
    if (currentSelectedFilter && level <= 5 && selection) {
      this.selectedCvocHierarchy[level - 1] = { level: level, hierarchyID: currentSelectedFilter.hierarchyId, hierarchyName: currentSelectedFilter.hierarchyName };
      this.modifyCVOCFilterCount(level + 1);
      this.disableApply = false;
      this.enableApply.emit({apply:this.disableApply,type:'cvoc',data:true});
    }
    else {
      this.modifyCVOCFilterCount(level);
    }
    this.filteredOptions_cvoc=null;
    this.persistCVOCValues();
  }

  //below method chnages the dropdown count and resets the value
  modifyCVOCFilterCount(level: number) {
    if (level > 5)
      return;
    this.cvocfilterIterations = new Array(level);
    this.cvocInputControls.slice(level).forEach(control => {
      control.setValue(null);
    });
    this.selectedCvocHierarchy.fill(null, level, 5);
  }

  displayFnCVOC(option: any) {
    return option?.hierarchyName;
  }

  ngOnDestroy()
  {
    this.persistCVOCValues();
  }

  persistCVOCValues()
  {
      this.selectedCvocHierarchy=this.selectedCvocHierarchy.filter(x=>x?.hierarchyID!=null)
      this._filterService.selectedCVOC.next(this.selectedCvocHierarchy)
  }
}
