export enum ColumnDataType {
    Text = 'text',
    Number = 'number',
    Currency = 'currency',
    Link='link'
  }

export interface ITableConfiguration
{
  columnList:string[];
  mapper:any;
  tableTitle:string;
  tableType:string;
  pageSizeOptions:number[];
  pageSize:number;
  img?:string;
}

export interface ITableMatcher
{
  fieldName:string;
  displayColumn:string;
  dataTypeColumn:ColumnDataType;
}