import { Component, Input } from '@angular/core';
import { TRANSFORMATION_TYPE } from '../../constants/app.constants';

@Component({
  selector: 'app-total-value',
  templateUrl: './total-value.component.html',
  styleUrl: './total-value.component.scss',
})
export class TotalValueComponent{

  @Input() totalValue: number;
  @Input() totalValueDeliveredInMillion: number;
  @Input() totalOpportunityInMillion: number;
  @Input() currencyCode: any;
  transformationType = TRANSFORMATION_TYPE;
}
