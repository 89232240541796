import { Component } from '@angular/core';
import { Input } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { IPreferences } from '../../IPreferences';
// import { CookieService } from 'ngx-cookie-service'; 
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';



@Component({
  selector: 'app-preferences',
  templateUrl: './preferences.component.html',
  styleUrl: './preferences.component.scss',
})
export class PreferencesComponent {
  @Input() userPreferenceClicked: boolean = false;
  isSettingsClicked: boolean = false;
  userPrerence: IPreferences[] = [];


  constructor(private ssoService: AuthenticationService,
    // public cookieService:CookieService,
    private authService: MsalService,
    private http: HttpClient,
    public translate : TranslateService) {}


  ngOnInit() {
    this.translate.stream('TVD').subscribe((values)=>{
      this.userPrerence = [
        {
          label: values.LOGOUT,
          img: '../../../../../assets/images/logout.png',
        },
      ];
    })
  }

  ngAfterViewInit(){
   
  }

   logout() {
    let payload = {
      emailId: this.authService.instance.getAllAccounts()[0].localAccountId,
      isLoggedOut: true,
    };
    const headers = new HttpHeaders().set('content-type', 'application/json');
    const url = `${environment.API_DOTNET_TVD_Logout_URL}`;
    const body = JSON.stringify(payload);
    this.http.post(url, body, { headers: headers }).subscribe(() => {
      this.redirectURL();
    });
    console.log('logged out');
}

redirectURL(){
     this.authService.logoutRedirect({
        postLogoutRedirectUri: environment.ECOLAB_WEB,
      });
}
  
  getPreference(prefer: IPreferences) {
    this.logout();
  }
  
}

