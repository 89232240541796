<table mat-table [dataSource]="dataSource" matSort class="table-wrapper">
  <ng-container *ngFor="let column of tableConfiguration?.mapper" [matColumnDef]="column.fieldName">
    <th mat-header-cell id="header-cell-style" *matHeaderCellDef mat-sort-header>{{column.displayColumn}}</th>
    @switch (column.dataTypeColumn) {
    @case (columnDataType.Currency) {
    <td mat-cell *matCellDef="let element" class='tbl-currency-col'>{{element[column.fieldName]|
      numberSuffix:transformationType.withCurrency : currencyCode }}</td>
    }
    @case (columnDataType.Link) {
    <td mat-cell *matCellDef="let element"> <img [src]="tableConfiguration.img" /> </td>
    }
    @default () {
    <td mat-cell *matCellDef="let element">{{element[column.fieldName]}}</td>
    }
    }
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="tableConfiguration?.columnList"></tr>
  <tr mat-row *matRowDef="let row; columns: tableConfiguration?.columnList;"></tr>
</table>
<mat-paginator [length]="tableConfiguration?.mapper.length" [pageSize]="tableConfiguration?.pageSize"
  [pageSizeOptions]="tableConfiguration?.pageSizeOptions" showFirstLastButtons></mat-paginator>