import { Component, HostListener, Input } from '@angular/core';
import { AuthenticationService } from '../../../core/services/authentication.service';
import { ECLOLAB, USER } from '../../constants/app.constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent {
  @Input() title: string = '';
  userName: any;
  displayPrefernce: boolean = false;
  preference: boolean = false;
  show: boolean;
  ecolab = ECLOLAB;
  user_icon = USER;
  constructor(
    public ssoService: AuthenticationService,
    // private headerService: HeaderService
  ) {
    // this.headerService.getHeaderFlag().subscribe((flag)=>{
    //   this.show = flag;
    // });
  }





  ngOnInit() {
    this.show = true;
    this.getUserName();
  }

  getUserName() {
    this.userName = this.ssoService.getLoggedInUserName();
  }


  @HostListener('document:click', ['$event']) onDocumentClick(event: Event) {
    this.preference = false;
    this.displayPrefernce = false;
  }


  openPreferenceMenu($event: Event) {
    this.preference = !this.preference;
    this.displayPrefernce = !this.displayPrefernce;
    $event.stopPropagation()
  }
}
