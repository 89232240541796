@if(!viewMoreData){
<div *ngIf="customerTilesData" class="tile" [ngClass]="isWaterEnergyWaste()? 'tiles-text-white':'tiles-text-blue' ">
  <div class="tile-header">
    <div class="tile-header-type">
      <div class="tile-header-type--align">
        <img class="tile-header-type-img" alt="tiles-type" [src]="tileImage">
        <p class="tile-header-type-text">{{customerTilesData?.tileName}}</p>
      </div>

    </div>
  </div>
  <div class="tile-data" [ngClass]="isWaterEnergyWaste()? 'tiles-text-white':'tiles-text-cost' ">
    <p>{{customerTilesData?.totalValueDelivered | numberSuffix:transformationType.withCurrency : currencyCode }} </p>
    <span class="title-data-amt">{{tvd}}</span>

    <svg *ngIf="customerTilesData?.quantityDelivered && customerTilesData?.quantityDeliveredUnit" class="vector-90"
      width="2" height="30" viewBox="0 0 2 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1V29" stroke="#ADAFAF" stroke-linecap="round" />
    </svg>

    <span class="tile-data-unit">{{customerTilesData?.quantityDelivered |
      numberSuffix:transformationType.withoutCurrency}}</span>
    <span class="tile-data-unit tile-data-unit--align"> &nbsp;{{customerTilesData?.quantityDeliveredUnit}}</span>
  </div>
  <div class="divider">
    <p class="divider-color"></p>
  </div>
  <div class="tile-highchart"></div>
  <div class="tile-opportunity">
    <div [attr.id]="customerTilesData?.tileName"></div>
    <div class="tile-data-tvo tiles-opportunit">{{customerTilesData?.opportunityAmount |
      numberSuffix:transformationType.withCurrency : currencyCode}}
      {{'TVD.INPIPELINE' | translate}}</div>
  </div>
</div>
}
<div *ngIf="viewMoreData" [ngClass]="isWaterEnergyWaste() ?'tiles-slide': 'view-data'">
  <div class="segmentedInfo">
    <div *ngFor="let info of customerTilesData?.segregatedInformation">
      <p>
        <ng-container>
          {{ info.quantityDelivered | numberSuffix:transformationType.withoutCurrency}} {{info.quantityDeliveredUnit}}
        </ng-container>
      </p>
    </div>
  </div>
  <div>
    <div class="howCalc" (click)="ToggleHelpTextOverlay()">
      <img class="drop-img" [src]="isWaterEnergyWaste() ? icon_white : icon_blue" />
      <span class="btn-help"
        [ngClass]="isWaterEnergyWaste() ? 'tiles-text-white' : 'tiles-text-blue'">{{'TVD.HOW_WAS_THIS_CAL' |
        translate}}</span>
    </div>
  </div>
</div>
