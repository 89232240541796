import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule, MatIconButton } from '@angular/material/button';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './components/header/header.component';
import { CardsComponent } from './components/cards/cards.component';
import { TilesDataComponent } from './components/tiles-data/tiles-data.component';
import { TotalValueComponent } from './components/total-value/total-value.component';
import { NumberSuffixPipe } from './pipe/numberConversionSuffix.pipe';
import { FooterComponent } from './components/footer/footer.component';
import { PreferencesComponent } from './components/preferences/preferences.component';
import { MatCardModule } from '@angular/material/card';
import { TranslationService } from '../features/tvd-setup/services/translation.service';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory } from '../features/tvd-setup/tvd-setup.module';
import { TestInputComponent } from './components/total-value/test-input/test-input.component';
// import {CookieService} from 'ngx-cookie-service';
import { EndUserSessionComponent } from './components/end-user-session/end-user-session.component';
import { TableComponent } from './components/table/table.component';
import {MatTableModule} from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatDivider } from '@angular/material/divider';
import { MatPaginatorIntl, MatPaginatorModule } from '@angular/material/paginator';
import { MatIconModule } from '@angular/material/icon';
import { MyCustomPaginatorIntl } from './components/table/custom-paginator';

@NgModule({
  declarations: [
    HeaderComponent,
    CardsComponent,
    TotalValueComponent,
    TilesDataComponent,
    NumberSuffixPipe,
    FooterComponent,
    PreferencesComponent,
    TestInputComponent,
    EndUserSessionComponent,
    TableComponent
   
  ],
  
  imports: [
    CommonModule,
    MatIconModule,
    MatIconButton,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule, 
    MatTableModule,
    MatSortModule,
    MatDivider,
    MatPaginatorModule,
    MatCardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })],
  providers: [
    TranslationService,
    {
      provide: MatPaginatorIntl, 
      useClass: MyCustomPaginatorIntl
    }
  ],
  exports: [
    MatInputModule,
    MatIconModule,
    MatIconButton,
    MatDivider,
    MatButtonModule,
    MatFormFieldModule,
    FormsModule,
    HeaderComponent,
    ReactiveFormsModule,
    CardsComponent,
    TilesDataComponent,
    TotalValueComponent,
    TestInputComponent,
    NumberSuffixPipe,
    FooterComponent,
    PreferencesComponent,
    TranslateModule,
    TableComponent
  ]
})
export class SharedModule { }
